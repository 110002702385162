@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --component-text-2xl: Inter;
  --font-inherit: inherit;

  /* font sizes */
  --font-size-4xs-3: 8.3px;
  --font-size-2xs-4: 10.4px;
  --font-size-12xs-7: 0.7px;
  --font-size-sm: 14px;
  --font-size-9xl-6: 28.6px;
  --component-text-2xl-size: 24px;
  --component-heading-large-2xl-size: 48px;
  --font-size-12xs: 1px;
  --font-size-21xl: 40px;
  --component-heading-small-xl-size: 30px;

  /* Colors */
  --color-white: #fff;
  --black: #000;
  --color-silver: rgba(255, 255, 255, 0.726);
  --gray-200: #e2e8f0;
  --color-dodgerblue: #0094ff;

  /* Paddings */
  --padding-2xs: 11px;
  --padding-13xl: 32px;

  /* border radiuses */
  --br-10xs-8: 2.8px;
  --br-7xs-7: 5.7px;
  --br-5xs: 8px;
}
