.card {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-5xs);
  background-color: var(--color-silver);
  border: 1px solid var(--gray-200);
  box-sizing: border-box;
  width: 477px;
  height: 162px;
}
.here {
  color: var(--color-dodgerblue);
}
.linkedInTitle {
  position: absolute;
  top: 31px;
  left: 41px;
  line-height: 100%;
  display: inline-block;
  width: 410px;
  height: 87px;
}
.card1,
.linkedInCard {
  position: absolute;
  width: 477px;
}
.card1 {
  top: 0;
  left: 0;
  border-radius: var(--br-5xs);
  background-color: var(--color-silver);
  border: 1px solid var(--gray-200);
  box-sizing: border-box;
  height: 161px;
}
.resumeTitle1 {
  top: 30px;
  left: 35px;
  line-height: 100%;
  display: inline-block;
  width: 410px;
  height: 79px;
}
.card2,
.resumeCard1,
.resumeTitle1 {
  position: absolute;
}
.card2 {
  top: 0;
  left: 0;
  border-radius: var(--br-5xs);
  background-color: var(--color-silver);
  border: 1px solid var(--gray-200);
  box-sizing: border-box;
  width: 1028px;
  height: 400px;
}
.myNameIs {
  margin: 0;
}
.resumeTitle2,
.xl,
.xl1 {
  position: absolute;
  left: 35px;
  display: inline-block;
}
.resumeTitle2 {
  top: 33px;
  font-size: 80px;
  line-height: 100%;
  width: 536px;
}
.xl,
.xl1 {
  top: 322px;
  line-height: 150%;
  width: 385px;
}
.xl1 {
  top: 235px;
  width: 505px;
}
.alt,
.imageIcon,
.src {
  position: absolute;
  width: 100%;
  left: 0;
}
.imageIcon {
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: var(--br-5xs);
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.alt,
.src {
  display: inline-block;
}
.src {
  top: 0;
}
.alt {
  bottom: 0;
}
.image,
.titleCard {
  position: absolute;
  height: 400px;
}
.image {
  top: 0;
  left: 631px;
  width: 397px;
  overflow: hidden;
  text-align: right;
  font-size: var(--font-size-12xs);
}
.card3 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-5xs);
  background-color: var(--color-silver);
  border: 1px solid var(--gray-200);
  box-sizing: border-box;
  width: 488px;
  height: 364px;
}
.title,
.xl2 {
  position: absolute;
  top: 33px;
  left: 38px;
  line-height: 100%;
  display: inline-block;
  width: 419px;
}
.xl2 {
  top: 110px;
  font-size: var(--component-text-2xl-size);
  line-height: 150%;
  width: 385px;
}
.photosCard {
  position: absolute;
  top: 439px;
  left: 540px;
  width: 488px;
  height: 364px;
  font-size: var(--component-heading-large-2xl-size);
}
.titleCard {
  top: 0px;
  left: 0px;
  width: 1028px;
  font-size: var(--component-text-2xl-size);
}
.linkedInCard {
  top: 641px;
  left: 0px;
  height: 162px;
}
.resumeCard1 {
  top: 440px;
  left: 0px;
  width: 477px;
  height: 161px;
}
.html {
  height:100%;
  margin: 0; 
  padding: 0;
}
.mainPage {
  height: 100vh;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-21xl);
  color: var(--black);
  font-family: var(--component-text-2xl);
  background: linear-gradient(-45deg, #f8c5b0, #eed6df, #fce3a0, #91f3dc);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
.wrapper {
  position: relative;
  margin-top: 5%;
  margin-left: auto;
  margin-right: auto;
  max-width: 80%;
  height: 803px;
  width: 1028px;
}
