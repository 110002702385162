.card {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-5xs);
  background-color: var(--color-silver);
  border: 1px solid var(--gray-200);
  box-sizing: border-box;
  width: 1028px;
  height: 1868px;
}
.title,
.xl {
  position: absolute;
  top: 46.28px;
  left: 39.27px;
  font-size: var(--component-heading-large-2xl-size);
  line-height: 100%;
  display: inline-block;
  width: 124.85px;
  height: 146.63px;
}
.xl {
  top: 0;
  left: 3.02px;
  font-size: var(--component-heading-small-xl-size);
  line-height: 133%;
  width: 357.43px;
  height: 95.06px;
}
.productOwnerAnd {
  margin-bottom: 0;
}
.productOwnerAndDeveloperOf {
  margin: 0;
  padding-left: var(--padding-13xl);
}
.xl1,
.xl2,
.xl3,
.xl4 {
  position: absolute;
  line-height: 150%;
  display: inline-block;
}
.xl1 {
  top: 115.07px;
  left: 0;
  font-size: var(--font-inherit);
  font-family: var(--font-inherit);
  width: 956.51px;
  height: 128.83px;
}
.xl2 {
  top: 61.29px;
  left: 3.02px;
  width: 540.68px;
  height: 61.29px;
}
.xl3,
.xl4 {
  top: 0;
  left: 709.71px;
  width: 243.66px;
  height: 109.97px;
}
.xl4 {
  left: 48.33px;
  width: 208.42px;
  height: 42.53px;
}
.iolocationoutlineIcon {
  position: absolute;
  top: 3.75px;
  left: 0;
  width: 30.21px;
  height: 37.52px;
}
.location,
.sbbGroup {
  position: absolute;
  top: 0;
  left: 347.71px;
  width: 256.75px;
  height: 42.53px;
}
.sbbGroup {
  top: 495px;
  left: 42.29px;
  width: 956.51px;
  height: 243.9px;
}
.xl5,
.xl6,
.xl7,
.xl8,
.xl9 {
  position: absolute;
  display: inline-block;
}
.xl5 {
  top: 0;
  left: 3.02px;
  font-size: var(--component-heading-small-xl-size);
  line-height: 133%;
  width: 357.43px;
  height: 83.85px;
}
.xl6,
.xl7,
.xl8,
.xl9 {
  line-height: 150%;
}
.xl6 {
  top: 101.5px;
  left: 0;
  font-size: var(--font-inherit);
  font-family: var(--font-inherit);
  width: 956.51px;
  height: 113.63px;
}
.xl7 {
  top: 54.06px;
  left: 3.02px;
  width: 540.68px;
  height: 54.06px;
}
.xl8 {
  top: 4.41px;
  left: 711.85px;
  width: 243.66px;
  height: 97px;
}
.xl9 {
  top: 0;
  left: 48.33px;
  width: 208.42px;
  height: 37.51px;
}
.iolocationoutlineIcon1,
.location1 {
  position: absolute;
  top: 3.31px;
  left: 0;
  width: 30.21px;
  height: 33.1px;
}
.location1 {
  top: 4.41px;
  left: 346.36px;
  width: 256.75px;
  height: 37.51px;
}
.eaGroup,
.kiewitGroup {
  position: absolute;
  top: 1293px;
  left: 39.27px;
  width: 956.51px;
  height: 215.13px;
}
.kiewitGroup {
  top: 1653px;
  left: 39.27px;
}
.xl15,
.xl18 {
  position: absolute;
  top: 0;
  display: inline-block;
}
.xl15 {
  left: 3.02px;
  font-size: var(--component-heading-small-xl-size);
  line-height: 133%;
  width: 121.83px;
  height: 122.19px;
}
.xl18 {
  left: 712.73px;
  line-height: 150%;
  width: 243.66px;
  height: 109.97px;
}
.amazonGroup,
.location3,
.xl19 {
  position: absolute;
}
.location3,
.xl19 {
  top: 0;
  height: 43.78px;
}
.xl19 {
  left: 48.33px;
  line-height: 150%;
  display: inline-block;
  width: 149.01px;
}
.location3 {
  left: 349.73px;
  width: 197.34px;
}
.amazonGroup {
  top: 129px;
  left: 39.27px;
  width: 956.51px;
  height: 243.9px;
}
.card1,
.jobsCard,
.title1 {
  position: absolute;
}
.card1 {
  top: 0;
  left: 0;
  border-radius: var(--br-5xs);
  background-color: var(--color-silver);
  border: 1px solid var(--gray-200);
  box-sizing: border-box;
  width: 1028px;
  height: 477px;
}
.title1 {
  top: 36px;
  left: 39px;
  font-size: var(--component-heading-large-2xl-size);
  line-height: 100%;
  display: inline-block;
  width: 237px;
}
.xl20 {
  top: 105px;
  left: 39px;
  font-size: var(--component-heading-small-xl-size);
  line-height: 133%;
  width: 397px;
}
.xl20,
.xl21,
.xl22,
.xl23 {
  position: absolute;
  display: inline-block;
}
.xl21 {
  top: 260px;
  left: 39px;
  font-size: var(--component-heading-small-xl-size);
  line-height: 133%;
  width: 390px;
}
.xl22,
.xl23 {
  top: 109px;
  left: 747px;
  line-height: 150%;
  width: 242px;
}
.xl23 {
  top: 264px;
  left: 746px;
  width: 243px;
}
.masterOfInformation {
  margin: 0;
}
.xl24,
.xl25 {
  position: absolute;
  top: 156px;
  left: 39px;
  line-height: 150%;
  display: inline-block;
  width: 504px;
}
.xl25 {
  top: 310px;
  width: 301px;
}
.card2,
.educationCard {
  position: absolute;
  width: 1028px;
}
.card2 {
  top: 0;
  left: 0;
  border-radius: var(--br-5xs);
  background-color: var(--color-silver);
  border: 1px solid var(--gray-200);
  box-sizing: border-box;
  height: 257px;
}
.title2,
.xl26,
.xl27 {
  position: absolute;
  top: 27px;
  left: 34px;
  line-height: 100%;
  display: inline-block;
  width: 237px;
  height: 25.86px;
}
.xl26,
.xl27 {
  top: 98px;
  left: 39px;
  font-size: var(--component-heading-small-xl-size);
  line-height: 133%;
  width: 397px;
  height: 21.55px;
}
.xl27 {
  top: 150px;
  font-size: var(--component-text-2xl-size);
  line-height: 150%;
  width: 945px;
  height: 38.79px;
}
.card3,
.sideGigsCard {
  position: absolute;
  width: 1028px;
}
.card3 {
  top: 0;
  left: 0;
  border-radius: var(--br-5xs);
  background-color: var(--color-silver);
  border: 1px solid var(--gray-200);
  box-sizing: border-box;
  height: 689px;
}
.title3,
.xl28 {
  position: absolute;
  top: 27px;
  left: 31px;
  font-size: var(--component-heading-large-2xl-size);
  line-height: 100%;
  display: inline-block;
  width: 237px;
  height: 54.69px;
}
.xl28 {
  top: 163px;
  left: 34px;
  font-size: var(--component-heading-small-xl-size);
  line-height: 133%;
  width: 397px;
  height: 21.55px;
}
.xl29 {
  top: 208px;
  left: 34px;
  line-height: 150%;
  width: 945px;
  height: 38.79px;
}
.xl29,
.xl30,
.xl31,
.xl32,
.xl33 {
  position: absolute;
  display: inline-block;
}
.xl30 {
  top: 103px;
  left: 34px;
  line-height: 150%;
  width: 945px;
  height: 38.79px;
}
.xl31,
.xl32,
.xl33 {
  left: 36px;
}
.xl31 {
  top: 268px;
  font-size: var(--component-heading-small-xl-size);
  line-height: 133%;
  width: 397px;
  height: 21.55px;
}
.xl32 {
  top: 313px;
  line-height: 150%;
  width: 945px;
  height: 85px;
}
.xl33 {
  top: 405px;
  font-size: var(--component-heading-small-xl-size);
  line-height: 133%;
  width: 397px;
  height: 21.55px;
}
.xl34 {
  top: 450px;
  line-height: 150%;
  width: 945px;
  height: 157px;
}
.xl34,
.xl35,
.xl36 {
  position: absolute;
  left: 36px;
  display: inline-block;
}
.xl35 {
  top: 579px;
  font-size: var(--component-heading-small-xl-size);
  line-height: 133%;
  width: 397px;
  height: 21.55px;
}
.xl36 {
  top: 624px;
  line-height: 150%;
  width: 945px;
  height: 46px;
}
.educationCard {
  top: 131px;
  left: 0px;
  height: 477px;
}
.jobsCard {
  top: 646px;
  left: 0px;
  width: 1028px;
  height: 1868.13px;
}
.sideGigsCard {
  top: 2552px;
  left: 0px;
  height: 257px;
  font-size: var(--component-heading-large-2xl-size);
}
.skillsCard {
  position: absolute;
  top: 2847px;
  left: 0px;
  width: 1028px;
  height: 689px;
}
.wrapper {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 80%;
  height: 3,536px;
  width: 1028px;
}
.resumePage {
  position: relative;
  width: 100%;
  height: 3666px;
  overflow: hidden;
  text-align: left;
  font-size: var(--component-text-2xl-size);
  color: var(--black);
  font-family: var(--component-text-2xl);
  background: linear-gradient(-45deg, #f8c5b0, #eed6df, #fce3a0, #91f3dc);
	background-size: 500% 500%;
	animation: gradient 15s ease infinite;
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

